import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../shared/services/payment.service';
import { CustomValidator } from '../shared/ui-elements/validators/custom-validator';
import { SnackBarService } from './../shared/services/snackbar.service';
declare var ZestPayPop: any;

@Component({
  selector: 'app-client-payment-details',
  templateUrl: './client-payment-details.component.html',
  styleUrls: ['./client-payment-details.component.scss'],
})
export class ClientPaymentDetailsComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public snackBar: SnackBarService,
    private payment: PaymentService
  ) {}

  subPage: any;
  pageCode: string = JSON.parse(localStorage.getItem('pageCode')!);
  pageCreation: boolean = false;
  type!: string;
  singlePaymentPage!: {
    subPageCode: string;
  };
  publicKey!: string;

  getSinglePaymentPage() {
    this.pageCreation = true;
    this.payment.singlePayPage(this.singlePaymentPage.subPageCode).subscribe({
      next: (res: any) => {
        this.subPage = res.data;
        this.type = this.subPage.type === null ? 'STANDARD' : this.subPage.type;
        this.pageCreation = false;
      },
      error: (err) => {
        this.snackBar.openSnackBar(`Unable to complete`, 'error-snackbar');
        this.pageCreation = false;
      },
    });
  }

  ngOnInit(): void {
    this.singlePaymentPage = {
      subPageCode: this.route.snapshot.params['id'],
    };
    this.getSinglePaymentPage();
    window.scrollTo(0, 0);
  }
}
