import { Component, Input, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit {
  @Input() details: any;
  message!: string;
  singlePaymentPage!: {
    subPageCode: string;
  };
  setupStoreLink!: string;
  businessEmail: string | any;
  businessName: string | any;
  bizName: string | any;
  constructor(private payment: PaymentService) {
    this.setupStoreLink = environment.SETUP_ZEST_PROFILE_URL;
  }

  ngOnInit(): void {
    this.getBusinessName(this.details.page.merchantId);
  }

  getBusinessName(merchant: string) {
    this.payment.getMerchant(merchant).subscribe({
      next: (res: any) => {
        this.businessName = res;
      },
      error: (err) => {},
    });
  }
}
