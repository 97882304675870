import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PaymentService } from '../shared/services/payment.service';
import { CustomValidator } from '../shared/ui-elements/validators/custom-validator';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../shared/services/snackbar.service';
declare var ZestPayPop: any;

type Ticket = {
  name: string;
  price: string;
  ticketCategoryCode: string;
  quantityAvailable: number;
  quantityPurchased: number;
};

type TicketError = {
  message: string;
  ticketName: string;
};

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent implements OnInit {
  formValues: FormGroup;
  ticketSummary!: FormArray<any>;
  responseFields!: FormArray<any>;
  publicKey!: string;
  message!: TicketError;
  overallSubTotal!: number;
  singlePaymentPage: {
    subPageCode: string;
  };
  pageSubmit: boolean = false;
  formType!: string;
  @Input() key!: string;
  @Input() details: any;
  @Input() userResponse: any;
  ticketLimit!: number[];
  amountValue!: any;

  constructor(
    private fb: FormBuilder,
    private payment: PaymentService,
    public route: ActivatedRoute,
    private router: Router,
    public snackBar: SnackBarService
  ) {
    this.formValues = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      amount: [null, Validators.required],
      ticketPurchaseDetails: this.fb.array([]),
      response: this.fb.array([]),
      refNumber: [''],
      transactionStatus: [''],
    });

    this.singlePaymentPage = {
      subPageCode: '',
    };

    this.ticketSummary = this.formValues.get(
      'ticketPurchaseDetails'
    ) as FormArray;

    this.responseFields = this.formValues.get('response') as FormArray;
  }

  get purchasedTickets() {
    return this.ticketSummary;
  }

  get response() {
    return this.responseFields;
  }

  displayExtraInfo() {
    const fields = this.details.fields;
    const newFieldsResponse: any[] = [];
    fields.forEach((field: any) => {
      newFieldsResponse.push({ name: field, value: '' });
    });

    newFieldsResponse.forEach((field: any) => {
      const groupField = this.fb.group({
        [field.name]: [field.value, Validators.required],
      });
      this.responseFields.push(groupField);
    });
  }

  /**
   *
   * @param ticketName
   * @returns index of the control that matches the ticketname
   */
  possibleDuplicate(ticketName: string) {
    return this.ticketSummary.controls.findIndex((control: AbstractControl) => {
      return control.get('name')?.value === ticketName;
    });
  }

  ticketAvailability(quantityAdded: number, ticket: Ticket) {
    if (quantityAdded > ticket.quantityAvailable) {
      // Need CX to give the error message copy
      this.message = {
        message: `Sorry, only ${ticket.quantityAvailable} ${
          ticket.quantityAvailable > 1 ? 'tickets are' : 'ticket is'
        } available`,
        ticketName: ticket.name,
      };
      return true;
    } else {
      this.message = {
        message: '',
        ticketName: ticket.name,
      };
      return false;
    }
  }

  updateTicketSelection(index: number, updatedQuantity: number) {
    const rowToUpdate = this.ticketSummary.at(index);
    +updatedQuantity === 0
      ? this.ticketSummary.removeAt(index)
      : rowToUpdate.get('quantity')?.setValue(+updatedQuantity);
    rowToUpdate
      .get('subtotal')
      ?.setValue(+updatedQuantity * rowToUpdate.get('unitPrice')?.value);
  }

  /**
   *
   * @param $event event object to get the selected quantity of a particular ticket
   * @param ticket this is the ticket associated with the active selection
   * @description adds the selected ticket and its quantity to the ticketPurchaseDetails array
   */
  handleTicketSelection($event: any, ticket: Ticket) {
    if (!this.ticketAvailability(+$event.target.value, ticket)) {
      const isDuplicate = this.possibleDuplicate(ticket.name);
      if (isDuplicate === -1) {
        ticket = { ...ticket, quantityPurchased: +$event.target.value };
        this.ticketSummary.push(this.createTicketPurchaseDetails(ticket));
      } else {
        this.updateTicketSelection(isDuplicate, +$event.target.value);
      }
    }
  }

  calculateTotal() {
    this.overallSubTotal = 0;
    const subtotalArr = this.ticketSummary.controls.map((control) => {
      return control?.get('subtotal')?.value;
    });
    this.overallSubTotal = subtotalArr.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );
    this.formValues.controls.amount.setValue(this.overallSubTotal);
  }

  createTicketPurchaseDetails(ticket: Ticket) {
    return this.fb.group({
      ticketCategory: this.fb.group({
        ticketCategoryCode: [ticket.ticketCategoryCode, Validators.required],
      }),
      name: [ticket.name, Validators.required],
      quantity: [ticket.quantityPurchased, Validators.required],
      unitPrice: [ticket.price, Validators.required],
      subtotal: [+ticket.price * ticket.quantityPurchased, Validators.required],
    });
  }

  getAmount() {
    if (this.formType === 'STANDARD') {
      return this.formValues.get('amount')?.value;
    } else {
      return this.overallSubTotal;
    }
  }

  payWithZest() {
    let trxRef: string = '';
    let status: string = '';
    let handler = ZestPayPop.setup({
      key: this.publicKey, // Replace with your public key
      email: this.formValues.value.email,
      amount: this.getAmount() * 100,

      metadata: {
        source: 'Payment pages',
      },
      onClose: (response: any) => {},
      callback: (response: any) => {
        trxRef = response.reference;
        status = response.status;
        this.formValues.controls.transactionStatus.setValue(status);
        this.formValues.controls.refNumber.setValue(trxRef);
        this.postResponse();
      },
    });
    handler.openIframe();
  }

  postResponse() {
    this.pageSubmit = true;
    this.payment
      .sendResponse(this.singlePaymentPage.subPageCode, this.formValues.value)
      .subscribe({
        next: (res: any) => {
          this.router.navigate([
            'pay',
            this.singlePaymentPage.subPageCode,
            'success',
          ]);
          this.pageSubmit = false;
        },
        error: (err) => {
          this.snackBar.openSnackBar(`Unable to complete`, 'error-snackbar');
          this.pageSubmit = false;
        },
      });
  }

  getPublicKey(merchant: string) {
    this.payment.getKey(merchant).subscribe({
      next: (res: any) => {
        this.publicKey = res;
      },
      error: (err) => {},
    });
  }

  onSubmit() {
    console.log(this.formValues.value);
    if (this.formValues.invalid) {
      CustomValidator.validateAllFormFields(this.formValues);
      return;
    }
    this.payWithZest();
  }

  ngOnInit(): void {
    if (this.details) {
      this.formType =
        this.details.type === null ? 'STANDARD' : this.details.type;
      this.ticketLimit = [
        ...Array(this.details.ticketPurchaseLimit + 1).keys(),
      ];
      this.displayExtraInfo();
      this.getPublicKey(this.details.page.merchantId);
      if (this.details.fixed) {
        this.amountValue = `${this.details.amount}`;
        this.formValues.get('amount')?.setValue(this.details.amount);
      }
    }

    this.singlePaymentPage = {
      subPageCode: this.route.snapshot.params['id'],
    };
    this.ticketSummary.valueChanges.subscribe(() => {
      this.calculateTotal();
    });
  }
}
