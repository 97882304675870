import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AppComponent } from './app.component';
import { ClientPaymentDetailsComponent } from './client-payment-details/client-payment-details.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SuccessScreenComponent } from './success-screen/success-screen.component';
import { InvoicePaymentComponent } from './invoice-payment/invoice-payment.component';
import { TicketComponent } from './client-payment-details/components/ticket/ticket.component';
import { PaymentInformationComponent } from './payment-information/payment-information.component';
import { PaymentPageComponent } from './client-payment-details/components/payment-page/payment-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ClientPaymentDetailsComponent,
    SuccessScreenComponent,
    InvoicePaymentComponent,
    TicketComponent,
    PaymentInformationComponent,
    PaymentPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
