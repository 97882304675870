<section class="font-mont">
  <div
    class="bg-zestGreenLight px-4 lg:px-12 xl:px-[100px]"
  >
    <div class="py-7">
      <mat-icon class="svg-menu logo !w-[97px] !h-auto" svgIcon="zest"></mat-icon>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-8 pb-12">
      <div class="rounded-2xl overflow-hidden">
        <img [src]="details?.imageData.image_source" alt="banner" class="w-full object-contain" />
      </div>
      <div title="Event description and time">
        <p class="font-black text-[22px] leading-7 text-zestBlack !mb-0 pt-6 lg:pt-0">{{details?.subPageName}}</p>
        <div class="pb-8 pt-4 font-medium text-sm lg:text-base">
          <div class="flex justify-start items-center ">
            <mat-icon class="svg-menu" svgIcon="Calendar"></mat-icon>
            <p class="!mb-0 pl-2">{{details?.ticketDate | date: 'fullDate'}}</p>
          </div>
          <div class="flex justify-start items-center py-4">
            <mat-icon class="svg-menu" svgIcon="Time"></mat-icon>
            <p class="!mb-0 pl-2">{{details?.ticketTime}}</p>
          </div>
          <div class="flex justify-start items-start lg:items-center w-full">
            <mat-icon class="svg-menu w-2/12" svgIcon="Location"></mat-icon>
            <p class="!mb-0 pl-2 w-10/12">{{details?.location}}</p>
          </div>
        </div>
        <div class="border-t">
          <p class="font-bold text-base text-zestBlack !my-4">About this event</p>
          <p class="!mb-0 font-medium text-base text-zestTertiary-500">
            {{details?.description}}
          </p>
        </div>
      </div>
    </div>
    
  </div>
  <div class="py-16 px-4 lg:px-12 xl:px-[100px]">
    <app-payment-information [details]="details"></app-payment-information>
  </div>
</section>
