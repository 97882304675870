import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
  @Input() details: any;
  singlePaymentPage!: {
    subPageCode: string;
  };
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    public snackBar: SnackBarService
  ) {
    this.iconRegistry
      .addSvgIcon(
        'Calendar',
        this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Calendar.svg')
      )
      .addSvgIcon(
        'Location',
        this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Location.svg')
      )
      .addSvgIcon(
        'Time',
        this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/time.svg')
      )
      .addSvgIcon(
        'zest',
        this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/zest.svg')
      );
  }

  ngOnInit(): void {
    this.singlePaymentPage = {
      subPageCode: this.route.snapshot.params['id'],
    };
  }
}
